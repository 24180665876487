/*!
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2018
 * @package yii2-widgets
 * @subpackage yii2-widget-datepicker
 * @version 1.4.4
 *
 * Custom styling for Datepicker
 * Built for Yii Framework 2.0
 *
 * Author: Kartik Visweswaran
 * For more Yii related demos visit http://demos.krajee.com
 */
.input-group.date.disabled .input-group-addon .glyphicon {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.input-group.date .input-group-addon {
    cursor: default;
}

.input-group.date .kv-date-remove, .input-group.date .kv-date-calendar,
.input-group.date .kv-date-remove, .input-group.date .kv-date-calendar,
.input-group.input-daterange .kv-date-remove, .input-group.input-daterange .kv-date-calendar,
.input-group.input-daterange .kv-date-remove, .input-group.input-daterange .kv-date-calendar {
    cursor: pointer;
}

.input-group.input-daterange .input-group-addon {
    border-left: 1px solid #ccc;
}

.input-group.input-daterange .input-group-addon:last-child {
    border-right: 1px solid #ccc;
}
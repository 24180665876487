/*!
 * @package   yii2-grid
 * @author    Kartik Visweswaran <kartikv2@gmail.com>
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2014 - 2015
 * @version   3.0.2
 *
 * Gridview widget styling and enhancements for Bootstrap.
 * Built for Yii Framework 2.0
 * Author: Kartik Visweswaran
 * Year: 2015
 * For more Yii related demos visit http://demos.krajee.com
 */
.kv-panel-pager {
    min-height: 34px;
}

.kv-panel-pager .pagination {
    margin: 0 0 -5px 0;
    padding: 0;
}

.kv-panel-before {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.kv-panel-after {
    padding: 10px;
    border-top: 1px solid #ddd;
}

.kv-table-float {
    border-top: none;
    border-bottom: none;
    background-color: #fff;
}

.kv-align-center {
    text-align: center;
}

.kv-align-left {
    text-align: left;
}

.kv-align-right {
    text-align: right;
}

.kv-align-top {
    vertical-align: top !important;
}

.kv-align-bottom {
    vertical-align: bottom !important;
}

.kv-align-middle {
    vertical-align: middle !important;
}

.kv-nowrap {
    white-space: nowrap;
}

.kv-merged-header {
    border-bottom: 2px solid #ddd !important;
}

.kv-page-summary {
    border-top: 4px double #ddd;
    font-weight: bold;
}

.kv-table-footer {
    border-top: 4px double #ddd;
    font-weight: bold;
}

.kv-table-caption {
    font-size: 1.5em;
    padding: 8px;
    border: 1px solid #ddd;
    border-bottom: none;
    text-align: center;
}

.panel .kv-table-caption {
    border: none;
    border-bottom: 1px solid #ddd;
}

.kv-grid-loading {
    opacity: 0.5;
    background: #ffffff url('../img/loading.gif') center center no-repeat !important;
}

.kv-grid-loading * {
    background: transparent !important;
}

.kv-grid-loading td {
    border-color: #efefef !important;
}

.kv-grid-hide {
    display: none !important;
}

.kv-grid-toolbar .dropdown-menu {
    z-index: 1025;
}

.panel > .floatThead-wrapper > .table,
.panel .table-responsive > .floatThead-wrapper > .table,
.panel > .panel-collapse > .table {
    margin-bottom: 0;
}

.panel > .floatThead-wrapper > .table caption,
.panel .table-responsive > .floatThead-wrapper > .table caption,
.panel > .panel-collapse > .table caption {
    padding-right: 15px;
    padding-left: 15px;
}

.panel > .floatThead-wrapper > .table:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel > .floatThead-wrapper > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .floatThead-wrapper > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel > .floatThead-wrapper > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .floatThead-wrapper > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .floatThead-wrapper > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .floatThead-wrapper > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
}

.panel > .floatThead-wrapper > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .floatThead-wrapper > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .floatThead-wrapper > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .floatThead-wrapper > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive > .floatThead-wrapper:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
}

.panel > .floatThead-wrapper > .table:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel > .floatThead-wrapper > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .floatThead-wrapper > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel > .floatThead-wrapper > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .floatThead-wrapper > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .floatThead-wrapper > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .floatThead-wrapper > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
}

.panel > .floatThead-wrapper > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .floatThead-wrapper > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .floatThead-wrapper > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .floatThead-wrapper > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive > .floatThead-wrapper:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .floatThead-wrapper > .table + .panel-body,
.panel > .table-responsive > .floatThead-wrapper + .panel-body {
    border-top: 1px solid #ddd;
}

.panel > .floatThead-wrapper > .table > tbody:first-child > tr:first-child th,
.panel > .floatThead-wrapper > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
}

.panel > .floatThead-wrapper > .table-bordered,
.panel .table-responsive > .floatThead-wrapper > .table-bordered {
    border: 0;
}

.panel > .floatThead-wrapper > .table-bordered > thead > tr > th:first-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > thead > tr > th:first-child,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr > th:first-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr > th:first-child,
.panel > .floatThead-wrapper > .table-bordered > tfoot > tr > th:first-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tfoot > tr > th:first-child,
.panel > .floatThead-wrapper > .table-bordered > thead > tr > td:first-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > thead > tr > td:first-child,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr > td:first-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr > td:first-child,
.panel > .floatThead-wrapper > .table-bordered > tfoot > tr > td:first-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
}

.panel > .floatThead-wrapper > .table-bordered > thead > tr > th:last-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > thead > tr > th:last-child,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr > th:last-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr > th:last-child,
.panel > .floatThead-wrapper > .table-bordered > tfoot > tr > th:last-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tfoot > tr > th:last-child,
.panel > .floatThead-wrapper > .table-bordered > thead > tr > td:last-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > thead > tr > td:last-child,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr > td:last-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr > td:last-child,
.panel > .floatThead-wrapper > .table-bordered > tfoot > tr > td:last-child,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
}

.panel > .floatThead-wrapper > .table-bordered > thead > tr:first-child > td,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > thead > tr:first-child > td,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr:first-child > td,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr:first-child > td,
.panel > .floatThead-wrapper > .table-bordered > thead > tr:first-child > th,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > thead > tr:first-child > th,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr:first-child > th,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0;
}

.panel > .floatThead-wrapper > .table-bordered > tbody > tr:last-child > td,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr:last-child > td,
.panel > .floatThead-wrapper > .table-bordered > tfoot > tr:last-child > td,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tfoot > tr:last-child > td,
.panel > .floatThead-wrapper > .table-bordered > tbody > tr:last-child > th,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tbody > tr:last-child > th,
.panel > .floatThead-wrapper > .table-bordered > tfoot > tr:last-child > th,
.panel .table-responsive > .floatThead-wrapper > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
    border-radius: 3px;
}

.kv-clear-radio {
    float:none;
}

@media screen and (max-width: 480px) {
    .kv-table-wrap th, .kv-table-wrap td {
		display: block;
        width: 100%!important;
        text-align: center;
        font-size: 1.2em;
	}
    .kv-table-wrap tr > td:first-child {
        border-top: 3px double #ccc;
        margin-top: 10px;
        font-size: 2em;
    }
}
/* jQuery Resizable Columns v0.1.0 | http://dobtco.github.io/jquery-resizable-columns/ | Licensed MIT | Built Wed Apr 30 2015 14:24:25 */
/* Modifications by Kartik Visweswaran <kartikv2@gmail.com> for yii2-grid */
.rc-handle-container {
  position: relative;
}
.rc-handle {
  position: absolute;
  width: 7px;
  cursor: col-resize;
  margin-left: -3px;
  z-index: 2;
}
table.rc-table-resizing {
  cursor: col-resize;
}
table.rc-table-resizing thead,
table.rc-table-resizing thead > th,
table.rc-table-resizing thead > th > a {
  cursor: col-resize;
}
